/******************************************************
 * TYPOGRAPHY STYLES                                  *
 *                                                    *
 * Typography variables located in helpers/variables* *
 ******************************************************/
html, body {
  font-size: $rem;
  font-family: $preferred_font !important;
}
@media #{$medium-and-down} {
  html {
    font-size: $rem*$responsive-ratio;
  }
}

h1,h2,h3,h4,h5,h6 {
  color: $text-headline-color;
  margin: .5em 0;
}
p, span {
  font-size: 1em;
  font-weight: $bold;
}
option{
  line-height: 2;
  font-size: .9rem;
}
p, option, span {
  color: $text-default-color;
  margin: 0;
}
i {
  // margin-right: .5em;
  &.material-icons {
    cursor: pointer;
  }
}
.material-icons {
  display: inline-block;
  vertical-align: middle;
  transition: .3s ease-in-out;
}

// FONT SIZE CONFIG
.panel-fixed {
  font-size: 1rem;
}
.panel-box {
  font-size: .8rem;
}
.mdl-data-table {
  // override MDL
  font-size: inherit;
}
.progress {
  font-size: .7rem;
}
#inquiryDetail {
  .panel-footer {
    font-size: .9rem;
  }
}

.text {
  &-sm {
    font-size: .7em;
  }
  &-md {
    font-size: .8em;
  }
}

.dashboard-tab {
  font-size: .9em;
}

// FONT COLOR
.red {
  color: $color-red ;
}
.dark-red {
  color: $color-dark-red ;
}
.text-offset {
  //color: $color-offset ;
  color: $color-red;
}
.text-gray {
  color: $text-gray-color ;
}
.text-green {
  color: $text-green-color;
}


.headline {
  @extend h4;
  font-size: 1em;
  &-detail {
    @extend h5;
    font-size: 1.125em;
    font-weight: $bold;
  }
  &-note {
    @extend h6;
    font-size: .75em;
    font-weight: normal;
  }
  &-detail, &-note {
    color: $text-default-color;
  }
}
.content {
  line-height: 1.5;
  font-weight: $bold;
  &-title {
    color: $text-gray-color;
    font-size: .625em;
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    i.edit {
      margin-left: .3em;
      font-size: 1.2em;
      color: #1784bc;
    }
  }
  &-detail {
    font-size: .75em;
    word-wrap: break-word;white-space:normal;
  }
}


button, .btn-primary, .btn-secondary, .btn-flat {
  font-size: .7em;
  //letter-spacing: .72px;
  font-weight: $bold;
}

.btn-add {
  &:before, &:after {
    font-size: 1.7em;
  }
}

.inquiry-status {
  border: 2px solid $status;
  font-weight: $bold;
  color: #83A73A;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 1em !important;
  font-size: .625em;

    @each $name, $color in $status-color {
      &.#{$name} {
        color: $color;
        border-color: $color;
      }
    }
}

.warning {
  font-size: .75em;
  color: $text-warning-color;
  font-weight: $bold;
  &:before {
    content: "!";
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1em;
    border-radius: 50%;
    color: $text-white-color;
    background-color: $text-warning-color;
    text-align: center;
  }
}

.small-hint {
  font-size: 10px;
  color: #6a6a6a;
  font-style: normal;
}