#inquiryDetail {

      $color: #BABABA;
      select[disabled] {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
      }
      input[disabled],
      select[disabled] {
        //border-color: transparent;
        pointer-events: none;

        // ios fix
        -webkit-text-fill-color: #777 !important;
        -webkit-opacity: 1 !important;

        &::placeholder {
          font-size: 0;
        }
      }
      input,
      select {
        padding: 0.5em;
        //background-color: transparent;
        //border: 1px solid transparent;
        border: 1px solid #e6e6e6;
        transition: 0.2s linear;
        border-radius: 5px;
        &:active,
        &:focus {
          border-color: $color;
          outline: none;
        }
        &::placeholder {
          font-style: italic;
        }
      }
      input {
        //border-bottom-color: $color;
      }
      select {
        border-color: $color;
      }
        i {
          margin-right: 0;
        }
      td {
        padding: 10px;
      }
}

.input-radius {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #ebebeb;
  background-color: #ffffff;
  height: 28px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 2.5px;
  font-stretch: normal;
  color: #4a4a4a;
  &::placeholder {
    color:#9b9b9b;
  }
}

.input-radius:focus {
  border-radius: 5px;
}

.checkmark {
  position: absolute;
  // top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
