/**
 * Top Right Panel - Language
**/
#chooseLanguage {
	.div-language {
		float: right;
		margin-top: 4px;
		span {
			color: #9b9b9b;
			font-weight: normal;
			margin-right: 13px;
		}
		span:hover {
			color: #fff;
			font-weight: normal;
			margin-right: 13px;
		}
	}
}

.span-box-font-color {
    margin-left: 30px !important;
    top: 3px !important;
}