#schedule-board {
  #board {
    padding: 1rem;
    table {
      height: 100%;
      width: 100%;
      border-collapse: unset;
      border-radius: 5px;
      thead {
        width: calc(100%);
        td {
          text-align: center;
          text-transform: capitalize;
          font-weight: bold;
        }
      }
      tbody {
        display: block;
        overflow-y: hidden;
        //height: 850px;
        tr {
          width: 100%;
          &:first-child td {
            border-top: 0;
          }
          &:last-child td{
            border-bottom: 0;
          }
        }
      }
      thead, tbody tr {
        display: table;
        table-layout: fixed;
      }
      td {
        &:not(:last-child) {
          border-right: 1px solid $table-border-color;
        }
      }
      .time-cell {
        text-align: left;
        padding-left: 1em;
        padding-top: 0;
      }
      .item-cell {
        padding: .5em;
        .board-item {
          @extend .inquiry-status;
          border-radius: 3px;
          border-width: 1.15px;
          width: 100%;
          text-align: left;
          display: flex;
          align-items: center;
          margin-bottom: 1em;
          cursor: pointer;
          .board-time {
            flex-grow: 2;
          }
          .type_name {
            flex-grow: 3;
            text-transform: capitalize;
          }
          .unit {
            flex-grow: 1;
            text-align: right;
          }
          .type_name, .unit {
            color: $text-gray-color;
          }
        }
      }
    }
  }

  #filter {
    @extend .panel-box;
    .panel-box {
      margin: auto;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .schedule_date {
      position: relative;
      width: 100%;
    }
  }
}
