/**
 * Include component, helpers, and layout
 */

// VENDOR DEPENDENCY

@font-face
{
    font-family: 'montserratregular';
    font-weight: normal;
    font-style: normal;

    src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'), url('../fonts/montserrat-regular-webfont.woff') format('woff');
}

$preferred_font: 'montserratregular', sans-serif; //override mdl font
@import "vendors/mdl/material-design-lite";

// HELPERS
@import 'helpers/functions';
@import 'helpers/variables';

// COMPONENTS
@import 'components/typography';
@import 'components/buttons';
@import 'components/carousel';
@import 'components/forms';
@import 'components/cards';
@import 'components/tables';

// LAYOUT
@import 'layout/helper';
@import 'layout/global';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/login';
@import 'layout/notification';

@import 'layout/inquiryDetail';
@import 'layout/print';
@import 'layout/print_invoice';
@import 'layout/scheduleBoard';
@import 'layout/about_property';
@import 'layout/announcement';
@import 'layout/billing';
@import 'layout/setting'
