$margin : 12, 24, 36, 48;
$orientation: top, right, left, bottom;

@each $m in $margin {
  @each $o in $orientation {
    $letter: str-slice($o,1, 1);
    .m#{$letter}#{$m} {
      margin-#{$o}: #{$m}px !important;
    }
    .p#{$letter}#{$m} {
      padding-#{$o}: #{$m}px !important;
    }
  }
}

.full-width {
  width: 100%;
}

.block {
  display: block;
}


/*****************
 * Layout Helper *
 *****************/
%relative {
  position: relative;
}
%absolute {
  position: absolute;
}
$orientation: top left right bottom;
@each $o in $orientation {
  %#{$o} {
    #{$o}: 0;

    &-center {
      #{$o}: 50%;
      @if $o == top or $o == bottom {
        transform: translateY(-50%);
      }
      @else {
        transform: translateX(-50%);
      }
    }
  }
}

.allow-overflow {
  overflow: visible;
  white-space: nowrap;
}

.absolute {
  &-left {
    @extend %absolute, %left;
  }
  &-right {
    @extend %absolute, %right;
  }
}
.absolute-y-center {
  @extend %top-center;
}
.absolute-top {
  @extend %top;
}
.float {
  &-left {
    float: left;
  }
  &-right {
    float: right;
  }
}

.flex {
  display: flex;
}
.flex-right {
  @extend .flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-x-center {
  justify-content: center;
}
.flex-y-center {
  align-items: center;
}

.cell-width-auto {
  width: auto !important;
}
.no-wrap {
  white-space: nowrap;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix             { zoom: 1; pointer-events: none;} /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */

.widthSmallIcon{
  width: 4%;
}