/**********
 * COLORS *
 **********/
// base color
$color-primary: grey;
$color-primary-contrast: white;
$color-secondary: #f6a623;
$color-warning: #d0021b;
$color-gray: #f9f9f9;
$color-red: #D0011B;
$color-dark-red: #a51919;

$white: white;
$color-offset: #e7e7e7; // Typography Color

$overlay: rgba(22,22,22,.2);
$overlay-light: rgba(200,200,200,.4);
$overlay-dark: rgba(22,22,22,.4);

// typography color
$text-default-color: #4a4a4a; // default text fade black
$text-headline-color: #242424; // headline black
$text-gray-color: #9b9b9b; // offset gray
$text-white-color: #fff;
$text-red-color: $color-red;
$text-green-color: #95b457;
$text-warning-color: $color-warning; // Button Color

$status-color: (
  unassigned: #D12C2C,
  new: #D12C2C,
  received: #FF9E7D,
  process: #FF9E7D,
  billing-approval: #95b457,
  billing-rejected: #FF8C9A,
  assigned: #F6A623,
  // in-progress: #FFD800,
  in-progress: #6AAF1D,
  progress: #FFD800,
  done: #4A90E2,
  reschedule: #C7008C,
  cancelled: #FF5858,
  completed: #9B9B9B
);

// button color
$button-primary-bg: #a81919;
$button-secondary-bg: #4f94e3;
$button-flat-bg: $white;
$button-flat-color: $text-gray-color;// - #090909;
$button-flat-border-color: $color-offset;
$button-red-color: $button-primary-bg;

// status color
$status: #83A73A;

// panel
$panel-background: $white;
$panel-offset-background: darken($white, 2%);


// misc
$progress-line-color: #f35151;
$progress-line-todo-color: darken($color-gray, 10%);

$v-progress-color: #FFF4F4;
$v-progress-gray: darken($color-gray, 10%);
$v-progress-red: #A81919;
$v-progress-fade-red: #FFF4F4;

$table-border-color: rgba(0, 0, 0, 0.12);

/**************
 * TYPOGRAPHY *
 **************/

$rem: 16px; // Font weights
$regular: normal;
$bold: bold;

/************************************
 * SIZES & COMPONENT SPECIFIC VARS* *
 ************************************/
$responsive-ratio: .8;

$navbar-height: 60px;

// panel
$panel-padding-top: 1rem;
$panel-padding-left: 1rem;
$panel-padding-left-big: 3rem;
$panel-padding-full-width: 3rem;
$panel-padding-full-width-small: $panel-padding-full-width*$responsive-ratio;
$panel-distance: 2rem;
$panel-radius: 5px;

// progress
$progress-line-padding-side: 1rem;
$progress-line-padding-bottom: 1.2rem;
$progress-line-width: 3px;
$progress-check-size: .8rem;
$progress-check-padding: .3em;

$v-progress-max-height: 500px;
$v-progress-panel-margin-left: 3rem;
$v-progress-panel-margin-top: .3rem;
$v-progress-dot-size: .7rem;

// billing
$row-border-radius: 3px;

// button
$button-height: 2.2rem;
$button-small-height: 2rem;
$button-padding: 0 2em;
$button-selectable-padding: 0 5em 0 1em;
$button-radius: 3px;


// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 1025px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 1024px !default;
$large-screen: 1200px !default;
$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default;
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default;
$extra-large-and-up: 'only screen and (min-width : #{$large-screen-up})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default;
