
%btn-base, %btn-colored {
  @extend .mdl-button;
  border-radius: $button-radius;
  height: $button-height;
  line-height: $button-height;
  padding: $button-padding;
  text-transform: capitalize;
  z-index: 1;
  &:not(.no-margin) {
    margin: 0 .8rem;
    &.no-margin-right {
      margin-right: 0;
    }
    &.no-margin-left {
      margin-left: 0;
    }
  }
}
%btn-colored {
  @extend .mdl-button--colored;
  color: white;
}
.btn {
  &-primary {
    @extend %btn-colored;
    background-color: $button-primary-bg;
    height: $button-height;
    line-height: $button-height;
    text-transform: uppercase;
    &:hover {
      background-color: darken($button-primary-bg, 10%);
    }
    &:active, &:focus {
      background-color: darken($button-primary-bg, 20%);
    }
  }

  &-secondary {
    @extend %btn-colored;
    background-color: $button-secondary-bg;
    &:hover {
      background-color: darken($button-secondary-bg, 10%);
    }
    &:active, &:focus {
      background-color: darken($button-secondary-bg, 20%);
    }
    &.red {
      background-color: $button-red-color;
    }
  }

  &-flat {
    @extend %btn-base;
    background-color: $button-flat-bg;
    color: $button-flat-color;
    border: 1px solid $button-flat-border-color;
  }

  &-selectable {
    @extend .btn-flat;
    background: transparent !important;
    text-align: left;
    padding: $button-selectable-padding;
    &, .fs-form-wrap .panel-popup & {
      border: 2px dashed;
      &:hover, &.selected {
        border-color: transparentize($button-red-color, .5);
        border-style: solid;
      }
      &.selected {
        border-color: $button-red-color;
      }
    }
    // override fform style
    @at-root .fs-form-wrap .panel-popup button {
      &.btn-secondary {
        border: none;
        background-color: $button-secondary-bg;
      }
    }
  }

  &-secondary, &-flat {
    height: $button-small-height;
    line-height: $button-small-height;
  }

  &-initials {
    color: inherit;
    background-color: transparent;
    padding: .05em .2em;
    margin-right: .5em;
    border: 1px dotted;
    border-color: inherit;
    border-radius: $button-radius;
  }

  &-add {
    padding-left: ($button-height * 1.1) + 1rem;
    letter-spacing: 1px;
    &:after {
      content: '+';
      width: $button-height * 1.1;
      height: 100%;
      background: $overlay;
      position: absolute;
      left: 0;
      top: 0;
    }
    &.small {
      padding-left: $button-height + .25rem;
      &:after {
        background: none;
      }
    }
  }
 
  &-table {
    padding-left: ($button-height * 1.1) + 1rem;
    letter-spacing: 1px;
    &:after {
      content: '\f0ce';
      width: $button-height * 1.1;
      height: 100%;
      background: $overlay;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &-status {
    @each $name, $color in $status-color {
      &-#{$name} {
        background-color: $color;
        &:hover {
          background-color: darken($color, 5%);
        }
      }
    }
  }
  &-group-tab {
      position: relative;
      display: inline-block;
      vertical-align: middle;
  }
}

.dyn-link {
  font-family: 'montserratregular';
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e8ee9;
  cursor: pointer;
  text-decoration: none !important;
}

/**
** RADIO BUTTON STYLE
**/
/* The container */
.label-container-rb {
  display: block;
  position: relative;
  padding-left: 20px;
  padding-top: 3px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  margin-top: 5px !important;
}

/* Hide the browser's default radio button */
.label-container-rb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark-rb {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.label-container-rb:hover input ~ .checkmark-rb {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.label-container-rb input:checked ~ .checkmark-rb {
  background-color: #4785B9;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-rb:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.label-container-rb input:checked ~ .checkmark-rb:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.label-container-rb .checkmark-rb:after {
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
}

.filter-status {
  .btn-tab-status-active {
    background-color: white;
    color: #1c78bb;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom: 2px solid;
  }
  .btn-tab-status {
    height: 50px;
    text-transform: uppercase;
    font-family: montserratregular;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }  
} 