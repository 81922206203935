#print-area {
    /*$text-normal: #4a4a4a;
    $text-head: #7c7c7c;*/

    @media print {
      * {
        -webkit-print-color-adjust: exact;
      }
    }

      div {
        position: relative;
      }
      table {
        &, & td, & tr, & th {
          border-color: #242424;
          border-width: 2px;
          /*color: $text-normal !important;*/
          &:not(.text-center) {
            text-align: inherit;
          }
        }
        th {
          /*color: $text-head !important;*/
        }
      }
      .title-grid {
        position: relative;
          h4 {
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
      }

      .table-title {
        width: 100%;
        margin-top: 2em;
        display: block;
        @media print {
          color: white !important;
        }
        > h4 {
          background: #242424 ;
          color: white ;
          text-transform: uppercase;
          display: inline-block;
          /*border: solid 1px #7c7c7c;*/
          border: solid 2px #242424;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          padding: 0 1em;
          font-size: .8rem;
          height: 1.5rem;
          line-height: 1.5rem;
          /*&:first-letter {
            color: white !important;
          }*/
        }
      }

      .wo_number {
        /*color: $text-head;*/
        text-transform: uppercase;
      }

      p, span {
        font-weight: normal !important;
      }
      td {
        vertical-align: baseline;
      }
      @media print {
        p, span, td, tr, th {
          font-weight: normal !important;
          color: #242424;
          /*color: $text-normal !important;*/
          &:first-letter {
            font-weight: normal !important;
            /*color: $text-normal !important;*/
          }
        }
        th:first-letter {
            /*color: $text-head !important;*/
        }

        .table-title {
          width: 100%;
          margin-top: 2em;
          display: block;
          @media print {
            color: white !important;
          }
          > h4 {
            background: #242424 ;
            color: white ;
            text-transform: uppercase;
            display: inline-block;
            /*border: solid 1px #7c7c7c;*/
            border: solid 2px #242424;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: 0 1em;
            font-size: .8rem;
            height: 1.5rem;
            line-height: 1.5rem;
            /*&:first-letter {
              color: white !important;
            }*/
          }
        }


      }
      .mid-center {
        vertical-align: middle;
      }
}
