.table-striped {
    tbody {
        tr:nth-child(odd) {
            background-color: #fafafa
        }
    }
}

.table-radius {
    border-collapse: separate;
    border-radius:6px;
    border: solid 1px #e2e2e2;
    margin-top: 11px;
    td, th {
        height: 45.3px;
    }
    th {
        border-top: none;
        font-size: 12px;
        font-style: normal;
    }
    td:first-child, th:first-child {
         border-left: none;
    }
    thead {
        border-top: 1px solid #ebebeb;
    }
    tfoot {
        height: 40.7px;
    }
    .td-label {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
    }
    .th-center {
        text-align: center;
    }
}