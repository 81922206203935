.image-center {
  margin-left:auto;
  margin-right:auto;
  display:block;
}

.batch-info {
  font-family: montserratregular;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}
.update-info {
  font-family: montserratregular;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}
.update-disclaimer {
  font-family: Hind-SemiBold;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}

.batch-text-link {
  font-family: montserratregular;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4f94e3;
}

#utility {
  .cover-input-box {
      color: #9b9b9b;
      width: 255px;
      min-height: 66px;
      border: 1px dashed #9b9b9b;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;
      font-size: 10px;
  }
  .upload-box {
    width:100%;
    justify-content: center;
    padding-left:16px;
    max-height: 200px
  }
  .upload-box-preview {
    width:100%;
    justify-content: center;
    padding-left:16px;
    max-height: 200px
  }
}

.filter-status {
  min-height: 0.01%;
  padding-right: 36px;
  background: white;
  height: 51px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fbfbfb;

  .btn-billing-status {
    height: 50px;
    text-transform: uppercase;
    font-family: montserratregular;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .btn-billing-status-active {
    background-color: white;
    color: #1c78bb;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom: 2px solid;
  }
}

.btn-large {
  min-width: 114px;
  border-radius: 5px;
  margin-right: 10px;
  height: 40px;
  width: 131px;
}