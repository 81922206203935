@mixin table-eo() {
	.table-eo {
		width: 100%;
		border-left: 0px;
		font-size: 13px;
		font-family: hind_regularregular;
		color: #4a4a4a;
		margin-top: 10px;
		tr {
			position: relative;
			border-spacing: 0;
			background-position-x: 10px;
			padding: 10px 0;
			transition: background .1s;
			height: 54px;
			.grand-total {
				font-size: 12pt;
			}
		}
		thead {
			tr > .th-set-right {
				text-align: center;
			}
		}
		tbody, tfoot {
			tr > .th-set-right {
				text-align: center;
			}
		}
	}

	.table.table-eo > tbody > tr:last-child {
		border-bottom: 1px solid #ddd;
	}
	.table-eo > tbody > tr:nth-of-type(even) {
		background-color: #f8f8f8 !important;
	}

	.table.table-eo > thead > tr:last-child {
		border-bottom: 1px solid #ddd;
	}
}

#print-area-invoice {
	.page {
		width: 1268px;
		min-height: 1795px;
		padding: 71px 90px 38px 82px;
		margin: 15mm auto;
		border: 1px #D3D3D3 solid;
		border-radius: 5px;
		background: white;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		font-size: 11px;
	}

    div {
			position: relative;
    }
	.title-grid {
		position: relative;
	}
	p, span {
		font-weight: normal !important;
	}

	.footer {
		position: fixed !important;
		bottom: 6%;
		width: 76%;
		padding-right: 82px;
	}

	.tr-printout-footer td {
		vertical-align: top !important;
	}

	.page h4 {
		margin: 0;
		font-size: 24px;
		font-family: hind_regularregular;
	}

	.footer-title {
		font-weight: bold;
		font-size: 14px;
	}

	.line {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #ccc;
		padding: 0;
	}

	#print-area-invoice .table-title > h4, #print-area-invoice .table-title > .headline {
		border: none;
		background: none;
		color: #242424!important;
		font-weight: bold;
	}

	#print-area-invoice .table-title > h4,
	#print-area-invoice .table-title > .headline::first-letter {
		/*color: #7c7c7c!important;*/
		color: #242424!important;
	}

	.page table {
		margin-bottom: 5px;
	}

	.subpage {
		/*padding: 1cm;*/
		height: 257mm;
	}

	.bmt-align {
		vertical-align: bottom !important;
	}

	.no-border-top {
		border-top: none !important;
	}

	#table-middle-info {
		height: 19px;
	}

	/** start media print**/
	@media print {
		* {
			-webkit-print-color-adjust: exact;
		}

		th:first-letter {
			/*color: $text-head !important;*/
		}

		.table-title {
			width: 100%;
			margin-top: 2em;
			display: block;
			@media print {
				color: rgb(235, 35, 35) !important;
			}
			> h4 {
				background: #242424 ;
				color: white ;
				text-transform: uppercase;
				display: inline-block;
				/*border: solid 1px #7c7c7c;*/
				border: solid 2px #242424;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				padding: 0 1em;
				font-size: .8rem;
				height: 1.5rem;
				line-height: 1.5rem;
				&:first-letter {
					color: white !important;
				}
			}
		}
		.page {
			border: initial;
			border-radius: initial;
			width: 1268px;
			min-height: initial;
			box-shadow: initial;
			background: initial;
			page-break-after: always;
			font-family: hind_regularregular;
			margin: 0px;
			padding-left: 87px;
			padding-right: 90px;
		}
		.print-font {
			font-size: 13pt;
		}

		.footer {
			position: fixed !important;
			bottom: 6%;
			width: 85%;
			padding-right: 0px;
		}
		.footer-scsf {
			position: fixed !important;
			bottom: 52%;
			width: 85%;
			padding-right: 82px;
		}
		.footer-utility {
			position: fixed !important;
			bottom: 52%;
			width: 100%;
		}
		.bmt-align {
			vertical-align: bottom !important;
			font-size: 13pt;
		}
		.footer-title {
			font-weight: bold;
			font-size: 13pt;
		}
		#div-first-footer {
			display: none;
		}
	}
	/** end media print**/

	.card-panel {
    border: 1px solid #979797;
		border-radius: 8px;
		margin-top: 24px;
		&-header {
			border-bottom: 1px solid #979797;
			height: 56px;
		}
		&-title {
			padding-left: 38px;
			padding-top: 19px;
			margin-bottom: 19px;
			font-size: 9px !important;
			h4 {
				font-size: 14px;
				margin: 0px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #6a6a6a;
			}
		}
		&-content {
			padding-left: 38px;
			padding-right: 34px;
			p {
				margin: 0px 0px 0px 0px;
			}
		}
	}

	#info-total {
		font-size: 24px;
		font-weight: bold;
		color: #4a4a4a;
	}
}

@include table-eo();