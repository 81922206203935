.nav {
  li {
    div {
        a:hover, a:focus {
            background-color:#333333;
        }
        a {
            position: relative;
            display: block;
            padding: 10px 15px;
        }    
    }
  }
}
.dropdown-menu-notification {
  position: absolute;
  top: 100%;
  /* left: 0; */
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  /* padding: 5px 0; */
  /* margin: 2px 0 0; */
  list-style: none;
  font-size: 12px;
  text-align: left;
  /* background-color: #fff;
  border: 1px solid #ddd;
  border: 1px solid #ddd; */
  border-radius: 0;
/*   -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-background-clip: padding-box; */
  background-clip: padding-box;
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}
.navbar-nav > li > .dropdown-menu-notification {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.open > .dropdown-menu-notification {
  display: block;
  background-color: #333333;
}

.dropdown-menu-notification input[type=checkbox] + label::after {
  content: "\25bc";
  top: 25px;
}
.dropdown-menu-notification label::after {
  position: absolute;
  right: 0;
  /* top: 0; */
  display: block;
  width: 3em;
  height: 3em;
  line-height: 3;
  text-align: center;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
}
.dropdown-menu-notification label {
  /* display: block; */
  /* padding: 10px; */
  /* background: #16a085; */
  /* font-weight: bold; */
  line-height: 1.5em;
  cursor: pointer;
  /* margin-left: 66px; */
  margin-right: 10px;
  font-size: 12px;
  min-height: 62px;
  width: 370px;
/*   padding-top: 15px;
  padding-right: 66px; */
  padding-left: 60px;
}
.dropdown-menu-notification input:checked ~ .tab-content-menu {
  max-height: 100em;
}
.list-notification {
  position: relative;
  margin-bottom: 1px;
  width: 100%;
  overflow: hidden;
  /* border-bottom: 1px; */
  /* border-color: red; */
  border-bottom: #656464 1px solid;
}

.list-notification-group-divided .list-notification-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
}

.list-notification-group-divided .list-notification-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: none;
}
.list-notification-group-item:last-child {
  background-color: #4A4A4A;
  color: #fff;
  border-color: #656464;
  /* font-family: montserratregular; */
}
.list-group-divided .list-notification-group-item {
  background-color: transparent;
  border-width: 1px 0;
}
.notification-content-custom .text {
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 0;
  color: #fff;
  font-size: 12px;
  font-family: montserratregular;
  margin-left: 54px;
  padding-right: 70px;
}
.list-notification-group {
  background-color: #333333;
}
.list-notification-group-subitem {
  position: relative;
  display: block;
  /* padding: 10px 15px; */
  margin-bottom: -1px;
  /*background-color: #fff;*/
  /*margin-left: -75px;*/
  /*border: 1px solid #ddd;*/
  div {
    a {
      padding: 0px !important;
      div.notification-content-custom {
        div.text {
          border-bottom: 1px solid #282828;
        }
      }
    }
  }
}
.tab{
  &-notification {
    border-bottom: 1px solid #333333;
    height: 40px;
    &-title {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 12px;
      line-height: normal;
      font-weight: normal;
      text-align: center;
      cursor: pointer;
    }
  }
  &-active {
    border-bottom: 2px solid #1c78bb;
  }

}

.badge-notification {
  background: red;
  position: absolute;
  bottom: 16px;
  border: 1px solid transparent;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #d9230f;
  border-radius: 10px;
  margin-left: -15px;
  &-tab {
    right: 60px;
  }
}