#login {
  background: white;
  height: 100vh;
  width: 100%;
  padding: 50px 0;
  > .mdl-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$large-and-up} {
      &:first-child {
        border-right: 1px solid #dbdbdb;
      }
    }
    &:nth-child(2) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @media #{$large-and-up} {
        margin-left: 2rem;
        justify-content: center;
      }
      @media #{$medium-and-down} {
        h5 {
          text-align: center;
          display: block;
          width: 100%;
        }
      }
      p {
        display: block;
      }
      form {
        width: 100%;
      }
    }
    .input-group {
      @extend .mb24;
      position: relative;
      width: 100%;
      i.material-icons {
        position: absolute;
        right: 0;
        bottom: 0;
        line-height: 1.538462;
        opacity: .4;
        z-index: 2;
        margin-right: 10px;
        &.active {
          opacity: 1;
        }
        &:hover {
          opacity: .65;
        }
      }
    }
    button[type="submit"] {
      // display: block;
      margin: 0 auto;
    }
  }
  p, h4, h5, h6 {
    color: $text-default-color;
  }
}
