/*.layout-content {
  padding-top: 1.5 * $navbar-height;
}*/
.container {
  margin: 0 $panel-padding-full-width;
  padding: 0;
}
/**********************
 * Multipurpose Panel *
 **********************/
 %custom-shadow {
  box-shadow: 0 3px 4px 0 rgba(180, 180, 180, 0.14), 0 3px 3px -2px rgba(180, 180, 180, 0.2), 0 1px 8px 0 rgba(180, 180, 180, 0.12);
 }
.panel {
  $p: &;
  $border-gray: darken($color-gray, 3%);
  @extend %custom-shadow;
  background: $white;
  z-index: 1;

  &-header {
    padding: $panel-padding-top $panel-padding-left;
    border-bottom: 3px solid $border-gray;
  }

  &-content {
    padding: $panel-padding-top*.5 $panel-padding-left;

  }

  &-footer {
    //background: $color-gray;
    background-color: #ffffff;
    border: 0;
  }

  &-split {
    padding: 0 $panel-padding-left;

    > .mdl-grid > .mdl-cell {
      padding: $panel-padding-top*.5 0;
      border-right: 2px solid $border-gray;

      &.no-border,
      &:last-child {
        border-right: 0;
      }
    }

    > .mdl-grid > .mdl-cell {
      &:first-child {
        padding-left: 0 !important;
      }

      &:not(:first-child) {
        padding-left: $panel-padding-top;
      }
    }
  }

  &-collapsible {
    &.collapsed {
      > #{$p} {
        &-header {
          border-bottom: 0;
        }
      }
    }
    > #{$p} {
      &-header {
        border-bottom-width: 1px;
        * {
          margin-top: 0;
          margin-bottom: 0;
        }
        i.material-icons {
          transform: rotate(-180deg);
        }
      }
      &-content {
        max-height: $v-progress-max-height;
        overflow-y: auto;
        p:not(.headline) {
          font-style: italic;
          font-weight: $regular;
        }
        span {
          margin-right: 1em;
        }
      }
    }
  }

  &-collapsible-invoice-setting {
    &.collapsed {
      > #{$p} {
        &-header {
          border-top: 0;
          cursor: pointer;
          &.bottom {
            border-bottom: 0;
          }
        }
        &-header > .panel-arrow {
          float: right;
          background-repeat: no-repeat;
          background:url("../../img/arrow-down.png");
          width: 0px;
          height: 30px;
          margin-right: 50px;
          margin-top: 20px;
          background-position: center;
          background-repeat: no-repeat;          
        }
      }
    }
    > #{$p} {
      &-header {
        cursor: pointer;
        border-bottom-width: 1px;
        * {
          margin-top: 0;
          margin-bottom: 0;
        }
        i.material-icons {
          transform: rotate(-180deg);
        }
      }
      &-header > .panel-arrow {
        float: right;
        background-repeat: no-repeat;
        background:url("../../img/arrow-up.png");
        width: 30px;
        height: 30px;
        margin-right: 50px;
        margin-top: 20px;
        background-position: center;
        background-repeat: no-repeat;
      }
      &-content {
        max-height: $v-progress-max-height;
        overflow-y: auto;
        p:not(.headline) {
          font-style: italic;
          font-weight: $regular;
        }
        span {
          margin-right: 1em;
        }
      }
    }
  }

  &-box {
    margin: $panel-distance $panel-padding-full-width;
    border-radius: 5px;
    padding: 0;
    &.with-box-padding {
      padding: $panel-padding-top;
    }
  }

  &-popup {
    position: fixed !important;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(22,22,22,.7);
    padding: 100px;
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: .3s ease-in-out;
    #{$p} {
      &-header, &-footer{
        background-color: $white;
      }
      &-content {
        background-color: $white;
        max-height: 50vh;
        overflow: auto;
      }
      &-content-custom {
        background-color: $white;
        overflow: auto;
      }
    }
    &.show {
      opacity: 1;
      pointer-events: all;
      z-index: 99;
    }
    i {
      cursor: pointer;
    }
  }

  &-fixed {
    padding-top: $navbar-height;
  }

  .absolute-right {
    margin-right: $panel-padding-left;
  }

  * {
    @extend  %relative;
  }
}

@media #{$large-and-up} {
  .panel {
    &-fixed {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    &-fixed-wf3 {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
}
@media #{$medium-and-down} {
  .panel {
    &-box {
      margin: $panel-distance $panel-padding-full-width-small;
    }
    &-header {
      padding: $panel-padding-top $panel-padding-left*$responsive-ratio;
    }
    &-popup {
      padding-left: 10vw;
      padding-right: 10vw;
    }
    &-collapsible {
      .panel-header .absolute-right {
        white-space: nowrap;
        left: 0;
        max-width: 100%;
        overflow-x: auto;
        margin-left: 6rem;
        text-align: right;
      }
    }
    &-collapsible-invoice-setting {
      .panel-header .absolute-right {
        white-space: nowrap;
        left: 0;
        max-width: 100%;
        overflow-x: auto;
        margin-left: 6rem;
        text-align: right;
      }
    }
  }
}

// kevin hapus #staff,
  #billing, .list-table-container {

    table {
      background: none;
      border-collapse: separate;
      border-spacing: 0 .5em;
      tbody {
        td {
          border: 1px solid $color-offset !important;
          span {
            // fix height to match multiselect
            height: 34px;
            line-height: 34px;
          }
        }
        td:first-child {
          border-top-left-radius: $row-border-radius;
          border-bottom-left-radius: $row-border-radius;
        }
        td:last-child {
          border-top-right-radius: $row-border-radius;
          border-bottom-left-radius: $row-border-radius;
        }
        td:not(:only-child) {
          &:not(:first-child):not(:last-child) {
            border-left: 0 !important;
            border-right: 0 !important;
          }
          &:first-child {
            border-right: 0 !important;
          }
          &:last-child {
            border-left: 0 !important;
          }
        }
      }
    }

    $color: #BABABA;

    input,
    select {
      padding: 0.5em;
      background-color: transparent !important;
      border: 1px solid transparent;
      border-bottom-color: $color;
      transition: 0.2s linear;
      border-radius: 3px;
      &:active,
      &:focus {
        border-color: $color;
        outline: none;
      }
      &[disabled] {
        border-color: transparent;
        pointer-events: none;
        }
    }
    select {
      border-color: $color;
      &[disabled] {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
      }
    }
    .btn-flat, .btn-secondary {
      font-size: .8em;
    }
  }
  #billing {
    table:first-child {
      table-layout: fixed;
      width: 100%;
      td {
        overflow-x: auto;
        input {
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        &:last-child {
          padding-right: .5em;
        }
      }
    }
  }

    .tr-hover {
        tr{
          &:hover {
            background-color: darken($v-progress-fade-red, 1%) !important;
            i.material-icons {
              @extend .red;
            }
            cursor: pointer;
          }
        }
    }

/****************
 * Progress Bar *
 ****************/
// Progress Bar

.progress {
  margin: 0;
  //padding: 3rem 1rem;
  list-style-type: none;
  display: flex;
  background: $white;
  padding: $progress-line-padding-bottom*.5 $progress-line-padding-side $progress-line-padding-bottom;
  height: 100%;
  z-index: 0;

  &-line {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;
    line-height: 3em;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    .checkmark {
      background: $progress-line-todo-color;
    }

    &:after {
      position: absolute;
      display: block;
      z-index: 1;
      bottom: 0;
      left: -50%;
      width: 100%;
      content: "";
      border-top: $progress-line-width solid $progress-line-todo-color;
    }

    &:first-child {
      &:after {
        display: none;
      }
    }

    &.done {
      &:after {
        border-top-color: $progress-line-color;
        background-color: #E60000;
      }
      .checkmark {
        background-color: $progress-line-color;
      }
    }
  }

  &-vertical {
    display: block;
    background: none;
    border-left: 1px solid $color-offset;
    padding: .5rem 0;
    $padding-side: 1rem;
    > * {
      padding: 0 $padding-side;
    }
    li {
      clear: both;
      .panel {
        float: right;
        margin: $v-progress-panel-margin-top $v-progress-panel-margin-top $v-progress-panel-margin-top 0;
        width: calc(100% - #{$v-progress-panel-margin-left});
      }
      &:first-child {
        background-color: $v-progress-color;
      }
      // point dot
      &:after {
        content: "";
        width: $v-progress-dot-size;
        height: $v-progress-dot-size;
        border-radius: 50%;
        margin-top: $v-progress-panel-margin-top;
        margin-left: $padding-side;
        background-color: $v-progress-gray;
      }
      &:not(:last-child):before {
        content: "";
        border-left: 2px solid $color-offset;
        margin: $v-progress-panel-margin-top (($v-progress-dot-size*.5)-.05rem) + $padding-side;
        height: 100%;
      }
      &:before, &:after {
        @extend %absolute, %left, %top;
      }
    }
    &:first-child {
      > li {
        &:nth-child(2) {
          background: $v-progress-fade-red;
          &:after {
            background-color: $v-progress-red;
          }
        }
      }
    }
    .headline {
      color: $v-progress-red;
      border-top: 1px solid $color-offset;
      padding-top: 1em;
    }
  }

  .checkmark {
    position: absolute;
    z-index: 2;
    font-size: $progress-check-size;
    padding: $progress-check-padding;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    color: $white;
    background: $progress-line-todo-color;
    border-radius: 50%;
  }
}

/*************
 * Scrollbar *
 *************/

 ::-webkit-scrollbar {
     width: 6px !important;
     height: 3px !important;
 }

 ::-webkit-scrollbar-thumb {
     background-color: transparent !important;
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     border: 2px solid rgba(0,0,0,0.3) !important;
 }


 .tooltipped {
   display: inline-block;
   .tooltip {
     @extend .mdl-tooltip;
     background-color: white;
     color: black;
     border: 1px solid $color-offset;
     min-width: 200px;
     max-width: none;

     position: absolute;
     top: 150%;
     left: -100%;
     &:before, &:after {
       content: '';
       position: absolute;
       left: 50%;
       top: 0;
       transform: translate(-50%, -100%);
       border-color: transparent;
       border-style: solid;
     }
     &:before {
       border-bottom-color: lighten($color-offset, 3%);
       border-width: 10px;
     }
     &:after {
       border-bottom-color: white;
       border-width: 9px;
     }
     ul {
       text-align: left;
       li {
         white-space: nowrap;
         &:not(:first-child) {
           padding-top: 1em;
         }
         &:not(:last-child) {
           border-bottom: 1px solid $color-offset;
           padding-bottom: 1em;
         }
       }
     }
     p {
       line-height: 1.5em;
     }
   }
   &:hover .tooltip {
     @extend .is-active;
   }
 }

 i.material-icons {
   user-select: none;
 }


 input.schedule_date {
   background-color: white;
   border: 1px solid #a7a7a7 !important;
   border-radius: 5px;
   color: #a7a7a7;
   display: inline-block;
   //override vue datepicker style
   padding: .4rem !important;
   line-height: 1.5rem !important;
   font-size: .9rem !important;
   font-style: italic;
   font-weight: normal;
 }

 .icon-calendar {
   left: 15px !important;
 }
  .text-right-align {
    text-align: right;
  }