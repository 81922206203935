$bluebamms : #1c78bb;
$white : #fff;

.invoice-setting {
    .p-heading-first {
        height: 22px;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4a4a4a;
    }
    .p-heading-second {
        height: 18px;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #9b9b9b;
        margin-bottom: 16px;
        margin-top:9px;
    }
    .line-bottom {
        border-bottom: solid 1px #e0e0e0;
    }
    .category-header {
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .subcategory-header {
        padding-top: 20px;
        font-weight: bold;
        margin-left: -20px;
        font-size: 14px;
    }
    .subcategory-body {
        margin-top: -10px;
        margin-left: -20px;
        font-size: 14px;
    }
    .line-bottom-category {
        border-bottom: solid 1px #e0e0e0;
        padding-bottom: 10px;
    }
    .line-bottom-category-dooted {
        border-bottom: dotted 1px #e0e0e0;
        padding-bottom: 10px;
    }
    .card-setting {
        margin-top: 10px;
        padding-bottom: 0px !important;
    }
    .setting-title {
        color: #9b9b9b;
        /* padding-left: 142px; */
        margin-top: 6px;
        font-size: 12px;
        /* text-transform: uppercase; */
    }
    .preview-title {
        margin-top: 0;
        font-size: 14px;
        color: #202020;
    }
    .page {
        background: white;
        font-size: 11px;
        padding: 10px;
        font-family: "Hind", sans-serif;
        border: 1px solid #b1b1b1;
    }
    .table {
        border-color: #e2e2e2;
        thead tr th {
            border-bottom: 2px solid #e2e2e2;
        }
    }
    .no-border {
        border-top: none !important;
        border-bottom: none !important;
    }
    .no-border-bottom {
        border-bottom: none !important;
    }
    .bmt-align {
        vertical-align: bottom !important;
    }
    .card-panel {
        border: 1px solid #979797;
        border-radius: 8px;
        margin-top: 14px;
    }
    .card-panel-title {
        padding-left: 29px;
        padding-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 12px !important;
    }
    .card-panel-header {
        border-bottom: 1px solid #979797;
        height: 40px;
        font-family: "Hind", sans-serif;
    }
    .preview-image {
        color: #9b9b9b;
        border: 1px dashed #9b9b9b;
        border-radius: 5px;
        width: 168px;
        height: 48px;
        float: right;
/*         width: 100%;
        height: 100%; */
        /* padding: 10%; */
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;
        font-size: 11px;
    }
    .footer {
        left: 0;
        bottom: 30px;
        padding: 10px;
        width: 100%;
        border: 1px solid #b1b1b1;
        font-size: 11px;
    }
    .cover-input-box {
        color: #9b9b9b;
        width: 255px;
        height: 66px;
        border: 1px dashed #9b9b9b;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;
        font-size: 11px;
    }

    .cover-input-button {
        display: none;
    }
}

.div-radius {
    border-radius: 20px;
    background-color: $bluebamms;
    width: 32px;
    height: 32px;
    color: $white;
    float: right;
    padding: 8px 0px 12px 10px;
}