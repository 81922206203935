#about-property {
    ul {
        padding-left: 0;
        li {
            padding: 2em 40px;
            &:not(:last-child) {
                border-bottom: 1px solid #e5e5e5;
            }
        }
    }
    label {
        display: block;
        &.control-label:not(.no-margin) {
            margin-bottom: 2em;
        }
        &:not(.control-label) {
            color: $text-gray-color;
            margin-top: 1em;
        }
    }
    input.form-control {
        margin-top: 0 !important;
    }

    .input-group {
        width: calc(100% - 2em);
        margin-left: 2em;
        margin-bottom: 1em;
        .mce-tinymce {
            width: 80% !important;
        }
    }

    .logo-input, .cover-input {
        &-box {
            color: $text-gray-color;
            width: 10rem;
            height: 10rem;
            border: 1px dashed $text-gray-color;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            cursor: pointer;
            span {
                font-size: .9em;
                text-align: center;
                color: inherit;
            }
        }
        &-button {
            display: none;
        }
    }
    table {
        .logo-input-box {
            width: 3rem;
            height: 3rem;
            border-radius: 8px;

        }
    }
    .cover-input-box {
        width: 50%;
        height: 5rem;
        margin-bottom: 0;
        padding: 0;
        justify-content: flex-start;
        img {
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }
    input[type="text"] {
        border-radius: 5px;
        display: block;
        width: 50%;
        margin: 1em 0;
    }

    $radius: 5px;
    table.mdl-data-table {
      width: 100%;
      border: 0;
      border-collapse: separate;
      border-spacing: 0 1em;
      tbody {
          tr {
              margin-bottom: 1em;
              td {
                  vertical-align: middle;
                  padding-top: 1em;
                  padding-bottom: 1em;
                  $border: 1px solid #e6e6e6;
                  border: $border;
                  &:first-child {
                      border-top-left-radius: $radius;
                      border-bottom-left-radius: $radius;
                      border-left: $border !important;
                      border-right: 0;
                  }
                  &:last-child {
                      border-top-right-radius: $radius;
                      border-bottom-right-radius: $radius;
                      border-left: 0;
                      border-right: $border !important;
                  }
                  &:not(:first-child):not(:last-child) {
                      border-left: 0;
                      border-right: 0;
                  }

                  .edit_button {
                      color: #f58220;
                  }
                  .delete_button {
                      color: #f35e70;
                  }
                  .save_button {
                      color: #4a90e2;
                  }
                  .edit_button, .delete_button, .save_button {
                      font-size: .85em;
                      cursor: pointer;
                  }
                  i {
                      font-size: inherit;
                      margin-top: -.3em;
                  }

                  input.form-control {
                      margin: 0;
                      width: 100%;
                      &:disabled {
                          background-color: transparent;
                          border-color: transparent;
                      }
                  }
              }
              &:hover {
                  background-color: inherit;
              }
          }
      }
      td {
        text-align: left;
        border: 0;
        vertical-align: top;
        overflow: unset;
      }
    }

    .placeholder_image {
        display: block;
        margin: .5em auto;
        max-width: 70%;
        &.inline-block {
            display: inline-block;
        }
    }
    .cover-input-box .placeholder_image {
        margin: auto 1em;
    }


    .submit_button {
        text-align: right;
        display: block;
        .btn-secondary {
            margin-right: 0;
        }
    }
}
