
  .panel {
    &-header button.btn-primary.absolute-right {
       margin-right: $panel-padding-full-width;
    }
    &-footer {
      .mdl-grid .mdl-cell {
        padding: 0;
      }
      .button-container {
        button:not(:last-child) {
          margin-right: 0;
        }
      }
    }
    &-fixed {
      &-wf3{
        .panel {
          &-header, &-footer {
            padding-top:17px;
            padding-left: 90px;
          }
          &-content {
            padding-left: 90px;
            &.urgent {
              border-left: 5px solid darken($color-red, 2%);
            }
          }
          &-footer {
              // padding-top: 0;
              // padding-bottom: 0;
              .button-container {
                  .button-inquiry {
                      display: inline-block;
                      text-transform: uppercase;
                      font-weight: bold;
                      font-size: .7em;
                      text-align: center;
                      img {
                          display: block;
                          margin: 0 auto;
                      }
                      cursor: pointer;
                      padding: 1em 1.5em;
                      &:first-child {
                          padding-left: 2em;
                      }
                      &:last-child {
                          padding-right: 2em;
                      }
                  }
              }
              .border-right {
                  border-right: 1px solid $color-offset;
              }
              .border-left {
                  border-left: 1px solid $color-offset;
              }
          }
        }
      }
      .panel {
        &-header, &-footer {
          padding: $panel-padding-top $panel-padding-full-width;
        }
        &-content {
          padding: 0 $panel-padding-full-width;
          &.urgent {
            border-left: 5px solid darken($color-red, 2%);
          }
        }
        &-footer {
            // padding-top: 0;
            // padding-bottom: 0;
            .button-container {
                .button-inquiry {
                    display: inline-block;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: .7em;
                    text-align: center;
                    img {
                        display: block;
                        margin: 0 auto;
                    }
                    cursor: pointer;
                    padding: 1em 1.5em;
                    &:first-child {
                        padding-left: 2em;
                    }
                    &:last-child {
                        padding-right: 2em;
                    }
                }
            }
            .border-right {
                border-right: 1px solid $color-offset;
            }
            .border-left {
                border-left: 1px solid $color-offset;
            }
        }
      }
    }
    .mdl-cell {
      margin: 0;
    }
    &.collapsed {
      .material-icons {
        transform: none;
      }
    }

    @media #{$medium-and-down} {
        &-header button.btn-primary.absolute-right {
           margin-right: $panel-padding-full-width-small;
        }
        &-fixed {
          &-wf3{
            > .panel {
              &-header, &-footer {
                padding: $panel-padding-top $panel-padding-full-width-small;
              }
              &-content {
                padding: 0 $panel-padding-full-width-small;
                > .mdl-grid {
                  flex-wrap: nowrap;
                    overflow-x: auto;
                  > .mdl-cell {
                    &:first-child {
                      padding-right: 1em;
                    }
                  }
                }
              }
              &-footer {
                overflow-x: auto;
                @media only screen and (min-width: 1024px) {
                    .mdl-cell {
                      //width: auto;
                   }
                }
              }
            }
          }
          > .panel {
            &-header, &-footer {
              padding: $panel-padding-top $panel-padding-full-width-small;
            }
            &-content {
              padding: 0 $panel-padding-full-width-small;
              > .mdl-grid {
                flex-wrap: nowrap;
                  overflow-x: auto;
                > .mdl-cell {
                  &:first-child {
                    padding-right: 1em;
                  }
                }
              }
            }
            &-footer {
              overflow-x: auto;
              @media only screen and (min-width: 1024px) {
                  .mdl-cell {
                    //width: auto;
                 }
              }
            }
          }
        }
    }
  }

#inquiryDetail {
  select[name="remark"] {
    margin-top: .2em;
    margin-left: 1em;
    padding: 0 .5em;
    border-radius: $button-radius;
    border-color: $button-flat-border-color;
    &:hover, &:active, &:focus {
      border-color: inherit;
    }
  }
  .progress {
    p {
      margin-bottom: 1.6em;
    }
  }

  #progress {
    .inquiry-status {
      margin-top: $panel-padding-top;
      margin-right: $panel-padding-top;
      font-style: initial;
      font-weight: $bold;
      height: auto;
    }
  }

  #staff {

    .panel-footer.schedule {
      background-color: white;
      .duration-cell {
        margin-left: 2rem !important;
        font-weight: bold;
        input {
          width: 4rem;
          margin-right: .5em;
          &:not(.first) {
            margin-left: 1.5em;
          }
        }
      }
      .content-detail {
        font-size: .9em;
      }

      @media #{$medium-and-down} {
        .duration-cell {
          margin-left: 0 !important;
        }
      }

      hr {
        margin: 2rem auto 0;
      }
    }

  }


  // Billing and Staff Panel (Table)
  table.mdl-data-table {
    width: 100%;
    border: 0;
    tr {
      &:hover {
        background-color: inherit;
      }
    }
    td {
      text-align: left;
      border: 0;
      vertical-align: top;
      overflow: unset;
    }
  }

  // Area multiselect popup
  .selectable-grid {
    @extend .mt24;
    .mdl-cell {
      .btn-selectable {
        width: calc(100% - 1em);
        margin-bottom: 1em;
      }
    }
  }
}
